import { Box, Container, Heading } from "@chakra-ui/react";
import { useSearchParams } from "react-router-dom";
import InviteCard from "../../components/login/InviteCard";

type Props = {
  code?: string;
};
const InviteScreen = ({ code }: Props) => {
  const [searchParams] = useSearchParams();
  const inviteCode =
    code !== undefined ? code : searchParams.get("code") || undefined;
  return (
      <chakra-scope>
        <Box pt="4%">
          <Container py={6} maxW="container.md">
            <Heading>Welcome to CertCrowd</Heading>
            <InviteCard inviteCode={inviteCode} />
          </Container>
        </Box>
      </chakra-scope>
  );
};

export default InviteScreen;

import { Button, HStack, Text, VStack } from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useApiMutation, useApiQuery } from "../../utilities/apibelRequest";
import useToast from "../../utilities/useToast";
import { Card, CardContent, CardHeading } from "../chakra/card";
import Spinner from "../chakra/common/Spinner";

type Props = {
  inviteCode?: string;
};
const InviteCard = ({ inviteCode }: Props) => {
  const navigate = useNavigate();
  const { displayToast } = useToast();
  const changeAccountMutation = useApiMutation("app/changeUserAccount");

  const [isChangingAccount, setIsChangingAccount] = React.useState(false);
  const inviteDetails = useApiQuery(
    "app/invite",
    { code: inviteCode || "" },
    { enabled: inviteCode !== undefined },
  );
  const replyToInviteMutation = useApiMutation("app/replyToInvite");

  const handleClickAccept = async () => {
    if (!inviteCode) return;
    try {
      const newAccountID = await replyToInviteMutation.mutateAsync({
        code: inviteCode,
        accept: true,
      });
      displayToast({
        title: "Accepted",
        description: "Invite accepted",
        status: "success",
      });
      setIsChangingAccount(true);
      await changeAccountMutation.mutateAsync({
        accountID: newAccountID,
      });

      navigate("/");
      window.location.reload();
    } catch (e) {
      displayToast({
        title: "Error",
        description: "Failed to accept invite",
        status: "error",
      });
    }
  };

  const handleClickDecline = async () => {
    if (!inviteCode) return;
    try {
      await replyToInviteMutation.mutateAsync({
        code: inviteCode,
        accept: false,
      });
    } catch (e) {
      console.error("Failed to decline invite");
    }
    navigate("/");
    window.location.reload();
  };

  return (
    <Card>
      <CardHeading title="Account Invite" />
      <CardContent>
        <VStack w="full">
          {inviteDetails.isFetching || isChangingAccount ? (
            <Spinner />
          ) : inviteDetails.isError ? (
            <Text>Something went wrong.</Text>
          ) : !inviteDetails.data ? (
            <Text>This invite is invalid or expired</Text>
          ) : (
            <>
              <VStack w="full" align="start">
                <Text>
                  You have been invited to join{" "}
                  <Text as="span" color="brand.700">
                    {inviteDetails.data.AccountSystemName ||
                      inviteDetails.data.AccountName}
                  </Text>
                </Text>
                <Text>Would you like to join?</Text>
              </VStack>
              <HStack w="full" justify="end">
                {replyToInviteMutation.isLoading ? (
                  <Button
                    isLoading
                    loadingText={
                      replyToInviteMutation.variables?.accept
                        ? "Acceping..."
                        : "Declining..."
                    }
                  />
                ) : (
                  <>
                    <Button
                      onClick={handleClickDecline}
                      variant="outline"
                      colorScheme="red">
                      Decline
                    </Button>
                    <Button onClick={handleClickAccept} variant="solid">
                      Accept
                    </Button>
                  </>
                )}
              </HStack>
            </>
          )}
        </VStack>
      </CardContent>
    </Card>
  );
};

export default InviteCard;

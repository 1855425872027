import {
  Button,
  chakra,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  UseDisclosureReturn,
  VStack
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormProvider, useForm } from "react-hook-form";
import { z } from "zod";
import ShortTextField from "../../../../components/chakra/form/formComponents/ShortTextField";
import useToast from "../../../../utilities/useToast";

export type CreateEmailModalMode =
  | {
      type: "create";
    }
  | {
      type: "verify";
      email: string;
    };

type Props = {
  modalState: UseDisclosureReturn;
  modalMode: CreateEmailModalMode;
  setModalMode: (mode: CreateEmailModalMode) => void;
  onRefresh: () => void;
  onCreateEmail: (email: string) => Promise<string | undefined>;
};

const schema = z.object({
  newEmail: z.string().email(),
});

export default function CreateEmailModal({
  modalState,
  onRefresh,
  onCreateEmail,
  modalMode,
  setModalMode
}: Props) {
  const { displayToast } = useToast();

  const form = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: zodResolver(schema),
    defaultValues: {
      newEmail: "",
    },
  });

  const handleSubmit = async () => {
    try {
      const emailAddr = form.getValues("newEmail");
      const newID = await onCreateEmail(emailAddr);
      if (newID) {
        displayToast({
          status: "success",
          title: "Email added",
        });
        onRefresh();
        setModalMode({ type: "verify", email: emailAddr });
      } else {
        throw new Error();
      }
    } catch (e) {
      displayToast({
        status: "error",
        title: "Failed to add Email",
        description: (e as Error)?.message || "Something went wrong",
      });
      onRefresh();
    }
  };

  const handleContinue = () => {
    modalState.onClose();
    setModalMode({ type: "create" });
  }

  const isBusy = false;
  return (
    <Modal
      onCloseComplete={form.reset}
      closeOnEsc={!isBusy}
      closeOnOverlayClick={!isBusy}
      isOpen={isBusy || modalState.isOpen}
      onClose={modalState.onClose}>
      <ModalOverlay />
      <chakra-scope>
        <ModalContent>
          {modalMode.type === "create" ? (
            <FormProvider {...form}>
              <chakra.form onSubmit={form.handleSubmit(handleSubmit)}>
                <ModalHeader>Add new email</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <VStack w="full" align="start">
                    <ShortTextField
                      fieldProps={{ label: "New email", isRequired: true }}
                      {...form.register("newEmail")}
                      inputPropsOverride={{ type: "email" }}
                    />
                  </VStack>
                </ModalBody>
                <ModalFooter>
                  <HStack>
                    <Button
                      onClick={modalState.onClose}
                      variant="solid"
                      colorScheme="gray">
                      Cancel
                    </Button>
                    <Button
                      isDisabled={!form.formState.isValid}
                      loadingText="Creating email"
                      isLoading={form.formState.isSubmitting}
                      type="submit">
                      Add email
                    </Button>
                  </HStack>
                </ModalFooter>
              </chakra.form>
            </FormProvider>
          ) : (
            <>
              <ModalHeader>Verify email</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <VStack w="full" align="start">
                  <Text>
                    A verification email has been sent to {modalMode.email}
                  </Text>
                  <Text>
                    Please click the link in the email to verify your email
                    address, then return to this page to continue.
                  </Text>
                </VStack>
              </ModalBody>
              <ModalFooter>
                <Button
                  onClick={handleContinue}>
                  Continue
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </chakra-scope>
    </Modal>
  );
}

import {
  Alert,
  Box,
  Center,
  Spinner,
  Text,
  VStack,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Link,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import React from "react";

export function FailedToLoadScreen({ info }: Props) {
  return (
    <chakra-scope>
      <Box py="12%">
        <Center h="full" w="full">
          <VStack>
            <Box mb="8">
              <Alert status="warning">
                <AlertIcon />
                <Box>
                  <AlertTitle>
                    Failed to load your account.
                  </AlertTitle>
                  <AlertDescription display="block">
                    Your login session may have expired. Please check your internet connection then refresh the page.
                  </AlertDescription>
                </Box>
              </Alert>
            </Box>
          </VStack>
        </Center>
      </Box>
    </chakra-scope>
  );
}

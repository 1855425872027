import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Center,
  Container,
  Heading,
  Link,
  Spinner,
  Text,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { AlertTriangle } from "react-feather";
import { useNavigate } from "react-router-dom";
import { Card, CardHeading } from "../../components/chakra/card";
import * as UserAccountStatus from "../../shared/constants/userAccountStatus";
import { UserAccount } from "../../shared/v2/definitions/accounts";
import { useApiMutation } from "../../utilities/apibelRequest";
import { useAuth } from "../../utilities/useAuth";

type Props = {
  userAccounts: UserAccount[];
};

const AccountDisabledScreen = ({ userAccounts }: Props) => {
  const navigate = useNavigate();
  const auth = useAuth();
  const updateAccountMutation = useApiMutation("app/changeUserAccount");
  const [status, setStatus] = React.useState<"pending" | "error" | "ready">(
    "ready",
  );

  const handleSelectAccount = async (selectedAccount: UserAccount) => {
    setStatus("pending");

    // Change current account
    await updateAccountMutation.mutateAsync({
      accountID: selectedAccount.accountID
    });

    if (selectedAccount.userAccountStatusID === UserAccountStatus.Pending) {
      navigate(`invite?code=${selectedAccount.userAccountID}`);
    } else {
      navigate("/");
    }

    window.location.reload();
  };

  const handleSignOut = async () => {
    await auth.signOut();
    navigate("/");
    window.location.reload();
  };

  const otherAccounts =
    userAccounts.filter(
      (acc) =>
        acc.active &&
        acc.userAccountStatusID !== null &&
        acc.userAccountStatusID !== UserAccountStatus.Inactive,
    ) || [];

  return (
    <chakra-scope>
      <Box py="4%">
        <Center h="full" w="full">
          {status === "pending" ? (
            <Spinner
              speed="1.25s"
              thickness="4px"
              color="primary1.main"
              emptyColor="primary2.main"
              size="xl"
            />
          ) : status === "error" ? (
            <Container maxW="container.lg">
              <Center>
                <Alert w="fit-content" status="error">
                  <Box pr={2}>
                    <AlertTriangle />
                  </Box>
                  <AlertTitle>
                    Something went wrong. Refresh the page or{" "}
                    <Link color="blue.600" href="mailto:help@certcrowd.com">
                      contact our team
                    </Link>{" "}
                    for help.
                  </AlertTitle>
                </Alert>
              </Center>
            </Container>
          ) : (
            <Container maxW="container.lg">
              <Card>
                <CardHeading title="Failed to load your account" />
                <VStack p={4} align="flex-start" spacing={6}>
                  <Box>
                    <Text>
                      This system may have been disabled or you may not have
                      permission to view it.
                    </Text>
                    <Text>
                      Contact your account owner, or{" "}
                      <Link color="blue.600" href="mailto:help@certcrowd.com">
                        contact our team
                      </Link>{" "}
                      for help.
                    </Text>
                  </Box>
                  {otherAccounts.length >= 1 ? (
                    <VStack w="full" align="start">
                      <Heading fontSize="sm">
                        Switch to another account:
                      </Heading>
                      <VStack spacing={4} px={4} align="flex-start">
                        {otherAccounts.map((acc) => (
                          <Button
                            minW={0}
                            key={acc.accountID}
                            colorScheme="blue"
                            variant="link"
                            onClick={() => handleSelectAccount(acc)}>
                            {acc.accountName}
                          </Button>
                        ))}
                      </VStack>
                    </VStack>
                  ) : null}
                  <Button onClick={handleSignOut} colorScheme="red">
                    Log Out
                  </Button>
                </VStack>
              </Card>
            </Container>
          )}
        </Center>
      </Box>
    </chakra-scope>
  );
};

export default AccountDisabledScreen;

import {
    Button,
    ButtonGroup,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text
  } from "@chakra-ui/react";
  import { useRef } from "react";
  
  type Props = {
    email: string;
    isOpen: boolean;
    onClose: () => void;
    onDelete: (() => Promise<void>) | null;
  };
  
  export function ConfirmDeleteEmailModal({ isOpen, onClose, onDelete, email }: Props) {
      const initialFocusRef = useRef(null);
    return (
      <Modal initialFocusRef={initialFocusRef} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <chakra-scope>
          <ModalContent>
            <ModalHeader>Remove email?</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
             <Text>Are you sure you want to remove the email "{email}"?</Text>
             <Text>You will need to reverify the email address to add it back in the future.</Text>
            </ModalBody>
            <ModalFooter>
              <ButtonGroup>
                <Button ref={initialFocusRef} onClick={onClose} colorScheme="gray">
                  Cancel
                </Button>
                <Button colorScheme="red" onClick={() => {
                  if (onDelete) {
                    onDelete();
                  }
                }}>Delete</Button>
              </ButtonGroup>
            </ModalFooter>
          </ModalContent>
        </chakra-scope>
      </Modal>
    );
  }
  